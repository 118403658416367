import React from 'react'
import LongMenu from '../Shared/Menu';
import { Delete } from '@mui/icons-material';
import client from '../../client';
import { API_ENDPOINTS } from '../../client/endpoints';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
export default function AdvertisementCard({item}) {
  const deleteMutation = useCustomMutation({
    mutationFn: client.Advertisement.delete,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_ALL_ADVERTISEMENTS],
    },
  });

  return (
    <>
      <div className='bg-card shadow-md rounded-xl gap-4 p-5 flex flex-col relative'>
        <div>
          <div className='flex w-full justify-between'>
                <h1 className='text-main-light text-xl font-bold py-2'>{item.title}</h1>
            <LongMenu options={[
            {
              component: <Delete className='text-red-600'/> ,
              action: ()=> {
                deleteMutation.mutate(item._id)
              }
            }]}/>
            </div>
            <div className='h-64 text-center'>
              <img className='w-full h-full' src={item.image} alt={item.title} />
            </div>
          </div>
        </div>
    </>
  )
}
