import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import client from '../../client';
import { API_ENDPOINTS } from '../../client/endpoints';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import ScrollLoading from '../Shared/ScrollLoading';
import { CircularProgress } from '@mui/material';
export default function TransitionsCard({item}) {
  const {t} = useTranslation()
  const {id} = useParams()
  const date = new Date(item.createdAt);
  const [typeLoading,setTypeLoading]= React.useState('')
  const [error, setError] = React.useState('')

const formattedDate = date.toISOString().split('T')[0];

const updateTransactionsMutation = useCustomMutation({
  mutationFn: client.Transitions.update,
  queryToInvalidate: { queryKey: [API_ENDPOINTS.GET_USER(id), API_ENDPOINTS.GET_ALL_TRANSACTIONS, id] },
  onError:(error)=>{
    console.log(error)
    setError(error.message)
    setTimeout(() => {
      setError('')
    }, 3000);
  }
});
const Accept = ()=>{
  setTypeLoading('approved')
  updateTransactionsMutation.mutate({id:item._id,status:'approved'})
}
const Reject = ()=>{
  setTypeLoading('rejected')
  updateTransactionsMutation.mutate({id:item._id,status:'rejected'})
}
  return (
    <>
      <div className='bg-card shadow-md rounded-xl gap-4 py-6 flex flex-col relative  px-5'>
          <div className='flex items-center justify-between'>
            <div className="bg-main px-1 py-2 rounded-lg border-main-light text-main-light font-bold text-xl">{formattedDate}</div>
            {item.status ==='pending' ? 
            <div className='grid grid-cols-2 gap-2'>
              <button onClick={()=>Accept()} className="bg-main px-1 py-2 rounded-lg border-main-light text-main-light font-bold flex items-center justify-center text-xl">{(updateTransactionsMutation.isPending && typeLoading==='approved')?<CircularProgress color='secondary' className='!w-5 !h-5  text-center'/>:t('accept')}</button>
              <button onClick={()=>Reject()} className="bg-main px-1 py-2 rounded-lg border-main-light text-main-light font-bold flex items-center justify-center text-xl">{(updateTransactionsMutation.isPending && typeLoading==='rejected')?<CircularProgress color='secondary' className='!w-5 !h-5  text-center'/>:t('reject')}</button>
            </div>:
            <div className="bg-main px-1 py-2 rounded-lg border-main-light text-main-light font-bold text-xl">{t(`${item.status}`)}</div>
            }
          </div>
            {error  && <div className='text-red-500 text-center'>{error}</div>}
          <div className='flex w-full justify-between items-center'>
              <p className='font-bold text-xl text-main'>{item.amount>0?t('transaction_amount_add'):t('transaction_amount_remove')} <span className='text-main-light px-2'>{Math.abs(item.amount)} {t('dinar')}</span> {item.amount>0?t('from_your_account'):t('to_your_account')}</p>
          </div>
      </div>
    </>
  )
}
