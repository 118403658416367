import React from 'react'
import {  useAdvertisement } from '../../data/vistor'
import { useTranslation } from 'react-i18next';
import Loading from '../../Components/Shared/Loading';
import AddAdvertisement from '../../Components/Advertisement/AddAdvertisement';
import AdvertisementCard from '../../Components/Advertisement/AdvertisementCard';

export default function Advertisements() {
  const { t } = useTranslation();
  const { data , isPending } = useAdvertisement()
  const [openAdd, setOpenAdd] = React.useState(false);
  const closeAdd = () => setOpenAdd(false);
  const openAddModel = () => setOpenAdd(true);

  return (
    <div>
      <button
        className="btn-main-light px-5 py-3 rounded-lg font-bold text-2xl mb-4"
        onClick={openAddModel}
      >
        {t('add_advertisement')}
      </button>
      {isPending ? (
        <Loading />
      ) : data?.length ?(
        <>
        <h1 className='text-center text-3xl font-extrabold pb-5'>{t('advertisements')}</h1>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 rounded-md p-3'>
            {data.map((item,index)=>
            <AdvertisementCard item={item} key={index} />)
            }
        </div>
        </>
      ):(
        <div className='flex items-center justify-center w-full h-96'>
          <div className='text-center text-3xl font-extrabold'>{t('not_available_advertisements')}</div>
        </div>
      )}
        <AddAdvertisement handleClose={closeAdd} open={openAdd} />
    </div>
  )
}
