import React from 'react'
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from './../../client/endpoints';
import { useAuth } from '../../Components/auth/context';
import client from '../../client';
import { CircularProgress } from '@mui/material';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import  cookies  from 'js-cookie';
import { useTranslation } from 'react-i18next';

export default function Login() {
  const { t } = useTranslation();
  const locale = cookies.get('locale')
  const [visibility, setVisibility] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  let navigate = useNavigate()
  const { authorize } = useAuth();

  let validationSchema = Yup.object({
    user:Yup.string().required(),
    password:Yup.string().required(),
  })
  const loginMutation = useCustomMutation({
    mutationFn: client.auth.login,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_PROFILE],
    },
    onSuccess: (data) => {
      if (data.status) {
        authorize(data.data);
        navigate('/');
      }
    },
    onError: (error) => {
      setLoading(false)
      setError(error.message)
      setTimeout(() => {
        setError('')
      }, 5000)
    },
    
  });

  let loginFormik = useFormik({
    initialValues:{
      user:'',
      password:'',
      type:'lawyer'
    },
    validationSchema,
    onSubmit:(values)=>{
      setLoading(true)
      // navigate('/')
      loginMutation.mutate(values)
    }
  })

  return (
  <div className="w-full flex items-center justify-center min-h-screen bg-main-light">
    <div className='bg-card px-20 md:px-32 py-10 md:py-20 rounded-3xl shadow-2xl'>
      <form onSubmit={loginFormik.handleSubmit} className="w-52 md:w-96">
        <div className='text-center mb-5'>
          <h1 className="text-3xl font-bold text-main text-center">{t('sign_in')}</h1>
          <small className='text-main text-center '>{t('sign_in_message')}</small>
        </div>
        <label htmlFor="user" className="block mb-2 text-sm font-medium text-main">{t('input_user')}</label>
        <div className="relative mb-2">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
            <PersonIcon className="w-4 h-4 text-main"/>
          </div>
          <input onBlur={loginFormik.handleBlur} onChange={loginFormik.handleChange} value={loginFormik.values.user} type="text" id="user" name='user' className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full ps-10 p-2.5" placeholder={t('placeholder_user')} />
        </div>
        {loginFormik.errors.user && loginFormik.touched.user?<div className='text-red-500'>{loginFormik.errors.user}</div>:''}
        <div className={`${error?'mb-4':'mb-6'}`}>
          <label htmlFor="password" className="block my-2 text-sm font-medium text-main">{t('input_password')}</label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
              <LockIcon className="w-4 h-4 text-main"/>
            </div>
            <div className="absolute inset-y-0 end-0 flex items-center pe-3.5  cursor-pointer" onClick={()=> setVisibility(!visibility)}>
              {!visibility?<VisibilityIcon className="w-4 h-4 text-main "/>:<VisibilityOffIcon className="w-4 h-4 text-main cursor-pointer"/>}  
            </div>
            <input onBlur={loginFormik.handleBlur} onChange={loginFormik.handleChange} value={loginFormik.values.password} type={visibility?"text":"password"} id="password" name='password' className="input bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg  block w-full ps-10 p-2.5" placeholder={t('placeholder_password')} />
          </div>
          {loginFormik.errors.password && loginFormik.touched.password?<div className='text-red-500 mt-2'>{loginFormik.errors.password}</div>:''}
          {error  && <div className='text-red-500 text-center mt-2'>{error}</div>}
        </div>
        <button type='submit' disabled={!(loginFormik.isValid && loginFormik.dirty ) || loading} className='cursor-pointer btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold'>
          {loading?<CircularProgress style={{
            width: '20px',
            height: '20px',
            color: '#fff',
          }} />:t('sign_in')}</button>
      </form> 
    </div>
  </div>

  )
}
