import { CircularProgress } from '@mui/material';
import React from 'react';

export default function ScrollLoading() {
  return (
    <div className='w-full flex items-center justify-center'>
      <CircularProgress color='success' className='w-5 h-5 text-center'/>
    </div>
  )
}
