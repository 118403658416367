import Modal from "@mui/material/Modal";
import React from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import client from "../../client";
import { API_ENDPOINTS } from "../../client/endpoints";
import { useCustomMutation } from "../../hooks/use-custome-mutation";
import { CircularProgress } from "@mui/material";
import cookies from 'js-cookie';

export default function EditTerms({ handleClose, open, item }) {
  const locale = cookies.get('locale')
  const { t } = useTranslation();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const editTermsMutation = useCustomMutation({
    mutationFn: client.TermsConditions.update,
    queryToInvalidate: { queryKey: [API_ENDPOINTS.GET_ALL_TERMS] },
    onSuccess: (data) => {
      if (data.status) {
        setLoading(false);
        handleClose();
      }
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    },
  });

  const editTermsFormik = useFormik({
    initialValues: {
      _id : item?._id,
      title: item?.title || "",
      description: item?.description || "",
    },
    onSubmit: (values) => {
      setLoading(true);
      editTermsMutation.mutate(values);
    }
  });
  return (
    <Modal open={open} onClose={handleClose}>
      <div dir={locale==='en'?'rtl':'rtl'} className="bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-5 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
        <form onSubmit={editTermsFormik.handleSubmit} className="w-64 md:w-[500px]">
          <div className="text-center mb-5">
            <h1 className="text-xl md:text-2xl font-bold text-main text-center">
              {t('edit_terms')}
            </h1>
          </div>
          <div className="grid md:grid-rows-2 gap-x-3">
            <div>
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_terms_title')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={editTermsFormik.handleBlur}
                  onChange={editTermsFormik.handleChange}
                  value={editTermsFormik.values.title}
                  name="title"
                  type="text"
                  id="title"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  placeholder={t('placeholder_terms_title')}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_terms_description')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={editTermsFormik.handleBlur}
                  onChange={editTermsFormik.handleChange}
                  value={editTermsFormik.values.description}
                  name="description"
                  type="text"
                  id="description"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  placeholder={t('placeholder_terms_description')}
                />
              </div>
            </div>
          </div>
          <button type='submit' disabled={loading} className="btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold">
            {loading?<CircularProgress style={{
            width: '20px',
            height: '20px',
            color: '#fff',
          }} />:t('edit')}
          </button>
        </form>
      </div>
    </Modal>
  );
}
