/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
  Box,
  Button,
  CircularProgress,
  lighten,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../../data/vistor';
import { useTranslation } from 'react-i18next';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import client from '../../client';
import AddNotification from '../../Components/Notification/AddNotification';

const User = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);
  const [usersId, setUsersId] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [search, setSearch] = React.useState('');
  const [isVerified, setIsVerified] = React.useState(true);

  // Fetch users when search or pagination changes
  const { data: fetchedUsers = [], isPending: isLoadingUsers, isError: isLoadingUsersError } = useUsers({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    search,
    type:'lawyer',
    isVerified:isVerified
  });

  const navigate = useNavigate();

  const handleOpen = (data) => {
    setOpen(true);
    const users = data.map(item => item.original._id);
    setUsersId(users);
  };

  const handleClose = () => setOpen(false);
  const handleOpenNotification = (data) => {
    setOpenNotification(true);
    const users = data.map(item => item.original._id);
    setUsersId(users);
  };

  const handleCloseNotification = () => setOpenNotification(false);

  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`,
        id: 'name',
        header: `${t('name')}`,
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              maxHeight: '20px',
            }}
          >
            <div className='w-10 h-10'>
              <img
                alt="Profile_Image"
                src={row.original.profileImage}
                loading="lazy"
                className='w-full h-full rounded-full'
              />
            </div>
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        filterVariant: 'autocomplete',
        header: 'البريد الالكتروني',
        size: 300,
      },
      {
        accessorKey: 'phoneNumber',
        header: 'رقم الموبيل',
        enableClickToCopy: true,
        size: 20
      },
      {
        accessorFn: (row) => `${row.isVerified}`,
        id: 'isVerified',
        header: `${t('isVerified')}`,
        size: 20,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: cell.getValue() === 'true'
                ? theme.palette.warning.dark
                : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row.isVerifiedByCrm}`,
        id: 'isVerifiedByCrm',
        header: `${t('isVerifiedByCrm')}`,
        size: 20,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: cell.getValue() === 'true'
                ? theme.palette.warning.dark
                : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue() === 'undefined' ? 'false' : cell.getValue()}
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row._id}`,
        id: 'viewProfile',
        header: `${t('viewProfile')}`,
        size: 200,
        Cell: ({ row }) => (
          <div className='flex items-center justify-start gap-2 cursor-pointer' onClick={() => navigate(`/user/${row.original._id}`)}>
            <AccountCircle className='text-gray-600' />
            {t('viewProfile')}
          </div>
        ),
      },
    ],
    [navigate, t],
  );

  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers?.data ?? [],
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'standard',
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100],
      shape: 'circular',
      variant: 'text',
    },
    columnResizeDirection: 'rtl',
    enableStickyHeader: true,
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          backgroundColor: lighten(theme.palette.background.default, 0.05),
          display: 'flex',
          gap: '0.5rem',
          p: '8px',
          justifyContent: 'space-between',
        })}
      >
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
        </Box>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <button onClick={()=> setIsVerified(true)} className={`${isVerified?'btn-main':'border-main text-main'} px-2 py-1 rounded-xl`}>{t('verified')}</button>
          <button onClick={()=> setIsVerified(false)} className={`${isVerified?'border-main text-main':'btn-main'} px-2 py-1 rounded-xl`}>{t('not_verified')}</button>
        </Box>
        <Box>
          <Box sx={{ display: table.getSelectedRowModel().rows.length > 0 ? 'flex' : 'none', gap: '0.5rem' }}>
            {/* <Button
              className="btn-main"
              color="inherit"
              onClick={() => handleOpen(table.getSelectedRowModel().rows)}
              variant="contained"
            >
              {t('edit_user_price')}
            </Button> */}
            <Button
              className="btn-main"
              color="inherit"
              onClick={() => handleOpenNotification(table.getSelectedRowModel().rows)}
              variant="contained"
            >
              {t('send_notifications')}
            </Button>
          </Box>
        </Box>
      </Box>
    ),
    state: {
      isLoading: isLoadingUsers,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isLoadingUsers,
      search,
      pagination,
    },
    manualPagination: true,
    rowCount: fetchedUsers?.pagination?.resultCount,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setSearch,
  });
  const [loading, setLoading] = React.useState(false);

  const createPlanMutation = useCustomMutation({
    mutationFn: client.Users.update_user_price,
    onSuccess: (data) => {
      if (data.status) {
        setLoading(false);
        handleClose();
        createPlan.resetForm();
      }
    },
  });

  const createPlan = useFormik({
    initialValues: {
      price: "",
      users:[],
      all: false
    },
    validationSchema: Yup.object({
      price: Yup.number().required(),
    }),
    onSubmit: (values) => {
      const selectedUserIds = usersId;
      const allSelected = selectedUserIds.length === fetchedUsers.data.length;
      const payload = {
        ...values,
        users: allSelected ? null : selectedUserIds,
        all: allSelected?true:false,
      };
      setLoading(true);
      createPlanMutation.mutate(payload);
    }
  });

  return <>
    <MaterialReactTable table={table} />
    <div>
      <AddNotification selectedUserIds={usersId} handleClose={handleCloseNotification} open={openNotification} />
      <Modal
        open={open}
        onClose={handleClose}
      >
        <div dir='rtl' className='bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-8 md:py-20 rounded-3xl shadow-2xl'>
          <form onSubmit={createPlan.handleSubmit} className="w-52 md:w-96">
            <div className='text-center mb-5'>
              <h1 className="text-xl md:text-2xl font-bold text-main text-center">{t('edit_user_price')}</h1>
            </div>
            <label htmlFor="price" className="block mb-2 text-sm font-medium text-main">{t('input_user_price')}</label>
            <div className="relative mb-2">
              <input 
              onBlur={createPlan.handleBlur}
              onChange={createPlan.handleChange}
              value={createPlan.values.price}              
              type="number" min='0' inputMode='numeric' pattern='[0-9]{0,14}' id="price" className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5" placeholder={t('placeholder_user_price')} />
            </div>
            <button type='submit' disabled={loading} className="btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold">
            {loading?<CircularProgress style={{
            width: '20px',
            height: '20px',
            color: '#fff',
          }} />:t('edit')}
          </button>
          </form>
        </div>
      </Modal>
    </div>
  </>;
};

const Lawyers = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <User />
  </LocalizationProvider>
);

export default Lawyers;
