/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
  Box,
  Button,
  lighten,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../../data/vistor';
import { useTranslation } from 'react-i18next';
import AddNotification from '../../Components/Notification/AddNotification';

const User = () => {
  const { t } = useTranslation();
  const [openNotification, setOpenNotification] = React.useState(false);
  const [usersId, setUsersId] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [search, setSearch] = React.useState('');

  // Fetch users when search or pagination changes
  const { data: fetchedUsers = [], isPending: isLoadingUsers, isError: isLoadingUsersError } = useUsers({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
    search,
    type:'client'
  });

  const navigate = useNavigate();
  const handleOpenNotification = (data) => {
    setOpenNotification(true);
    const users = data.map(item => item.original._id);
    setUsersId(users);
  };

  const handleCloseNotification = () => setOpenNotification(false);

  const columns = React.useMemo(
    () => [
      {
        accessorFn: (row) => `${row.name}`,
        id: 'name',
        header: `${t('name')}`,
        size: 250,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              maxHeight: '20px',
            }}
          >
            <div className='w-10 h-10'>
              <img
                alt="Profile_Image"
                src={row.original.profileImage}
                loading="lazy"
                className='w-full h-full rounded-full'
              />
            </div>
            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: 'email', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
        enableClickToCopy: true,
        filterVariant: 'autocomplete',
        header: 'البريد الالكتروني',
        size: 300,
      },
      {
        accessorKey: 'phoneNumber',
        header: 'رقم الموبيل',
        enableClickToCopy: true,
        size: 20
      },

      {
        accessorFn: (row) => `${row.isVerified}`,
        id: 'isVerified',
        header: `${t('isVerified')}`,
        size: 20,
        Cell: ({ cell }) => (
          <Box
            component="span"
            sx={(theme) => ({
              backgroundColor: cell.getValue() === 'true'
                ? theme.palette.warning.dark
                : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorFn: (row) => `${row._id}`,
        id: 'viewProfile',
        header: `${t('viewProfile')}`,
        size: 200,
        Cell: ({ row }) => (
          <div className='flex items-center justify-start gap-2 cursor-pointer' onClick={() => navigate(`/user/${row.original._id}`)}>
            <AccountCircle className='text-gray-600' />
            {t('viewProfile')}
          </div>
        ),
      },
    ],
    [navigate, t],
  );

  const table = useMaterialReactTable({
    columns,
    data: fetchedUsers?.data ?? [],
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowSelection: true,
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'standard',
    },
    muiPaginationProps: {
      rowsPerPageOptions: [10, 20, 30, 50, 100],
      shape: 'circular',
      variant: 'text',
    },
    columnResizeDirection: 'rtl',
    enableStickyHeader: true,
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          backgroundColor: lighten(theme.palette.background.default, 0.05),
          display: 'flex',
          gap: '0.5rem',
          p: '8px',
          justifyContent: 'space-between',
        })}
      >
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <MRT_GlobalFilterTextField table={table} />
          <MRT_ToggleFiltersButton table={table} />
        </Box>
        <Box>
          <Box sx={{ display: table.getSelectedRowModel().rows.length > 0 ? 'flex' : 'none', gap: '0.5rem' }}>
            <Button
              className="btn-main"
              color="inherit"
              onClick={() => handleOpenNotification(table.getSelectedRowModel().rows)}
              variant="contained"
            >
              {t('send_notifications')}
            </Button>
          </Box>
        </Box>
      </Box>
    ),
    state: {
      isLoading: isLoadingUsers,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isLoadingUsers,
      search,
      pagination,
    },
    manualPagination: true,
    rowCount: fetchedUsers?.pagination?.resultCount,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setSearch,
  });


  return <>
    <MaterialReactTable table={table} />
    <div>
      <AddNotification selectedUserIds={usersId} handleClose={handleCloseNotification} open={openNotification} />
    </div>
  </>;
};

const Users = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <User />
  </LocalizationProvider>
);

export default Users;
