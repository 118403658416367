import React from 'react';
import { useUsers } from '../../data/vistor';
import { useTranslation } from 'react-i18next';
import AddNotification from '../../Components/Notification/AddNotification';
import Loading from '../../Components/Shared/Loading';
import { Link } from 'react-router-dom';

export default function Notifications() {
  const { t } = useTranslation();
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 12,
  });
  const [userData, setUserData] = React.useState([]);
  const { data: fetchedUsers = {}, isPending: isLoadingUsers , isError } = useUsers({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
  });
  const [openAdd, setOpenAdd] = React.useState(false);
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);
  const closeAdd = () => setOpenAdd(false);
  const openAddModel = () => setOpenAdd(true);

  const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prevSelectedUserIds) => {
      if (prevSelectedUserIds.includes(userId)) {
        return prevSelectedUserIds.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUserIds, userId];
      }
    });
  };

  const ToggleSelectAll = () => {
    if (selectedUserIds.length === userData.length) {
      setSelectedUserIds([]);
    } else {
      setSelectedUserIds(userData.map((user) => user._id));
    }
  };

  React.useEffect(() => {
    if (fetchedUsers.data) {
      setIsInitialLoading(false);
      setUserData((prevUserData) => [...prevUserData, ...fetchedUsers.data]);
    }
    if(isError){
      setIsInitialLoading(false);
    }
  }, [fetchedUsers.data,isError]);

  React.useEffect(() => {
    const handleScroll = () => {
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      if (bottom && !isLoadingUsers && pagination.pageSize < fetchedUsers.pagination?.resultCount) {
        setPagination((prev) => ({
          ...prev,
          pageSize: prev.pageSize + 12,
        }));
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoadingUsers, fetchedUsers, pagination.pageSize]);

  return (
    <div>
      <div className='flex items-center justify-between'>
        <div>
          <button onClick={() => ToggleSelectAll()} className='px-5 text-white py-3 rounded-lg mb-4 font-bold text-2xl bg-main'>
            {t('select_all')}
          </button>
        </div>
        <div>
          {selectedUserIds.length > 0 && 
            <button
              className="btn-main-light px-5 py-3 rounded-lg mb-4 font-bold text-2xl"
              onClick={openAddModel}
            >
              {t('send_notifications')}
            </button>
          }
        </div>
      </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {userData.map(user => (
            <div key={user._id} className="bg-card flex justify-between p-2">
              <div className='flex gap-2'>
                <Link to={`/user/${user._id}`} className="w-20 h-20 border-main-light !border-4 rounded-lg overflow-hidden">
                  <img className="w-full h-full" src={user.profileImage} alt="" />
                </Link>
                <Link to={`/user/${user._id}`} className='text-main-light text-lg font-bold py-2'>{user.name}</Link>
              </div>
              <div>
                <input 
                  onChange={() => handleCheckboxChange(user._id)}
                  checked={selectedUserIds.includes(user._id)}                              
                  type="checkbox" value="" className="w-4 h-4 text-green-600 focus:text-green-700 bg-gray-100 border-gray-300 rounded"
                />
              </div>
            </div>
          ))}  
        </div>
        {isInitialLoading && <Loading/>}
      <AddNotification selectedUserIds={selectedUserIds} handleClose={closeAdd} open={openAdd} />
    </div>
  );
}
