import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './langs/en.json';
import ar from './langs/ar.json';
import cookies from 'js-cookie';

i18n  
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: ar,
      },
      ar: {
        translation: ar,
      },
    },
    lng: cookies.get('locale') || 'ar', // get the language from cookies or default to 'ar'
    fallbackLng:  cookies.get('locale') || 'ar', // fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
