import Modal from "@mui/material/Modal";
import { PlusOne, Remove } from "@mui/icons-material";
import React from 'react';
import { useTranslation } from "react-i18next";
import SwitchStatus from "../Shared/SwitchStatus";
import { useFormik, FieldArray, FormikProvider } from "formik";
import client from "../../client";
import { API_ENDPOINTS } from "../../client/endpoints";
import { useCustomMutation } from "../../hooks/use-custome-mutation";
import { CircularProgress } from "@mui/material";
import cookies from 'js-cookie';

const convertSecondsToYearsMonthsDays = (seconds) => {
  const years = Math.floor(seconds / (365 * 24 * 60 * 60));
  const remainingAfterYears = seconds % (365 * 24 * 60 * 60);
  const months = Math.floor(remainingAfterYears / (30 * 24 * 60 * 60));
  const days = Math.floor((remainingAfterYears % (30 * 24 * 60 * 60)) / (24 * 60 * 60));
  return { years, months, days };
};


const convertYearsMonthsDaysToSeconds = (years, months, days) => {
  return (years * 365 + months * 30 + days) * 24 * 60 * 60;
};

export default function EditPlan({ handleClose, open, item }) {
  const locale = cookies.get('locale');
  const { t } = useTranslation();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { years, months, days } = convertSecondsToYearsMonthsDays(item?.durationSeconds || 0);
  const [duration, setDuration] = React.useState({ years, months, days });

  const editPlanMutation = useCustomMutation({
    mutationFn: client.Plans.update,
    queryToInvalidate: { queryKey: [API_ENDPOINTS.GET_ALL_PLANS] },
    onSuccess: (data) => {
      if (data.status) {
        setLoading(false);
        handleClose();
      }
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    },
  });

  const editPlanFormik = useFormik({
    initialValues: {
      _id: item?._id,
      title: item?.title || "",
      subTitle: item?.subTitle || "",
      price: item?.price || "",
      role: "verified_lawyer",
      features: item?.features || [''],
      status: item?.status,
      isPremium: item?.isPremium,
    },
    onSubmit: (values) => {
      setLoading(true);
      values.durationSeconds = convertYearsMonthsDaysToSeconds(duration.years, duration.months, duration.days);
      values.features = values.features.filter(Boolean);
      if(values.durationSeconds === 0) {
        setError('يجب ان يكون وقت الباقة علي الاقل يوم واحد')
        setLoading(false)
        setTimeout(()=>{
          setError('')
        },5000)
        return
      }
      editPlanMutation.mutate(values);
    }
  });

  const handleDurationChange = (field, value) => {
    setDuration(prev => ({ ...prev, [field]: parseInt(value) }));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div dir={locale === 'en' ? 'rtl' : 'rtl'} className="bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-5 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
        <form onSubmit={editPlanFormik.handleSubmit} className="w-64 md:w-[500px]">
          <div className="text-center mb-5">
            <h1 className="text-xl md:text-2xl font-bold text-main text-center">
              {t('edit_plan')}
            </h1>
          </div>
          <div className="grid md:grid-cols-2 gap-x-3">
            <div>
              <label htmlFor="title" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_name')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={editPlanFormik.handleBlur}
                  onChange={editPlanFormik.handleChange}
                  value={editPlanFormik.values.title}
                  name="title"
                  type="text"
                  id="title"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  placeholder={t('placeholder_plan_name')}
                />
              </div>
            </div>
            <div>
              <label htmlFor="subTitle" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_sub_name')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={editPlanFormik.handleBlur}
                  onChange={editPlanFormik.handleChange}
                  value={editPlanFormik.values.subTitle}
                  name="subTitle"
                  type="text"
                  id="subTitle"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  placeholder={t('placeholder_plan_sub_name')}
                />
              </div>
            </div>
            <div>
              <label htmlFor="price" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_price')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={editPlanFormik.handleBlur}
                  onChange={editPlanFormik.handleChange}
                  value={editPlanFormik.values.price}
                  name="price"
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]{0,14}"
                  id="price"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  placeholder={t('placeholder_plan_price')}
                />
              </div>
            </div>
            <div className="col-span-2">
              <label htmlFor="durationSeconds" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_time')}
              </label>
              <div className="relative mb-2 grid grid-cols-3 gap-2">
                <div className="relative mb-2">
                  <label htmlFor="durationSeconds" className="block mb-2 text-sm font-medium text-main">
                    {t('year')}
                  </label>
                  <select
                    value={duration.years}
                    onChange={(e) => handleDurationChange('years', e.target.value)}
                    className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  >
                    {[...Array(11).keys()].map(year => (
                      <option key={year} value={year}>{year} {t('year')}</option>
                    ))}
                  </select>
                </div>
                <div className="relative mb-2">
                <label htmlFor="durationSeconds" className="block mb-2 text-sm font-medium text-main">
                    {t('month')}
                  </label>
                  <select
                    value={duration.months}
                    onChange={(e) => handleDurationChange('months', e.target.value)}
                    className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  >
                    {[...Array(13).keys()].map(month => (
                      <option key={month} value={month}>{month} {t('month')}</option>
                    ))}
                  </select>
                </div>
                <div className="relative mb-2">
                  <label htmlFor="durationSeconds" className="block mb-2 text-sm font-medium text-main">
                    {t('day')}
                  </label>
                  <select
                    value={duration.days}
                    onChange={(e) => handleDurationChange('days', e.target.value)}
                    className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  >
                    {[...Array(31).keys()].map(day => (
                      <option key={day} value={day}>{day} {t('day')}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="grid">
            <div>
              <label htmlFor="features" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_features')}
              </label>
            </div>
            <FormikProvider value={editPlanFormik}>
              <FieldArray
                name="features"
                render={arrayHelpers => (
                  <ul>
                    {editPlanFormik.values.features.map((feature, index) => (
                      <li className="grid grid-cols-5 gap-3" key={index}>
                        <div className="col-span-4 relative mb-2">
                          <input
                            type="text"
                            value={feature}
                            onChange={(e) => editPlanFormik.setFieldValue(`features[${index}]`, e.target.value)}
                            id={`features-${index}`}
                            className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                            placeholder={t('placeholder_plan_features')}
                          />
                        </div>
                        {index !== 0 ? (
                          <div className="col-span-1 flex items-center">
                            <Remove
                              className="text-white bg-main w-6 h-full p-1 rounded-full cursor-pointer"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </div>
                        ) : (
                          <div className="col-span-1 flex items-center">
                            <PlusOne
                              className="text-white bg-main w-6 h-full p-1 rounded-full cursor-pointer"
                              onClick={() => arrayHelpers.push('')}
                            />
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              />
            </FormikProvider>
            <div>
              <label htmlFor="status" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_status')}
              </label>
              <div className="relative mb-2">
                <SwitchStatus
                  checked={editPlanFormik.values.status}
                  onChange={() => editPlanFormik.setFieldValue('status', !editPlanFormik.values.status)}
                />
              </div>
            </div>
            <div className={`${error ? 'mb-4' : 'mb-6'}`}>
              <label htmlFor="isPremium" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_premium')}
              </label>
              <div className="relative mb-2">
                <SwitchStatus
                  checked={editPlanFormik.values.isPremium}
                  onChange={() => editPlanFormik.setFieldValue('isPremium', !editPlanFormik.values.isPremium)}
                />
              </div>
              {error && <div className='text-red-500 text-center mt-2'>{error}</div>}
            </div>
          </div>
          <button type='submit' disabled={loading} className="btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold">
            {loading ? <CircularProgress style={{ width: '20px', height: '20px', color: '#fff' }} /> : t('edit')}
          </button>
        </form>
      </div>
    </Modal>
  );
}
