import React from 'react'
import { useConsultantsAnalysis, useUserAnalysis , useTicketsAnalysis } from '../../data/vistor'
import Loading from '../../Components/Shared/Loading'
import { useTranslation } from 'react-i18next';
import { PieChart } from '@mui/x-charts';

export default function Home() {
  const { t } = useTranslation();
  const userAnalysis= useUserAnalysis()
  const consolationAnalysis= useConsultantsAnalysis()
  const ticketAnalysis= useTicketsAnalysis()
  const total = (userAnalysis.data?.map(item=> item.count).reduce((a, b) => a + b, 0))
  const totalConsolationAnalysis = (consolationAnalysis.data?.analysis?.map(item=> item.count).reduce((a, b) => a + b, 0))
  const totalConsolationType = (consolationAnalysis.data?.type?.map(item=> item.count).reduce((a, b) => a + b, 0))
  const totalTickets = (ticketAnalysis.data?.map(item=> item.count).reduce((a, b) => a + b, 0))
  const transformedArray =userAnalysis.data?.map((item, index) => ({
    id: index,
    value: item.count,
    label: t(`${item.type}`)
}));
  const transformedConsolationsAnalysis = consolationAnalysis.data?.analysis?.map((item, index) => ({
    id: index,
    value: item.count,
    label: t(`${item.status}`)
}));
  const transformedConsolationsType = consolationAnalysis.data?.type?.map((item, index) => ({
    id: index,
    value: item.count,
    label: t(`${item.type}`)
}));
  const transformedTickets = ticketAnalysis.data?.map((item, index) => ({
    id: index,
    value: item.count,
    label: t(`${item._id}`)
}));

  return (
    <div>
      {userAnalysis.isPending ? (
        <Loading />
      ) : (
        <>
      <h1 className='text-center text-3xl font-extrabold pb-5'>{t('analytics')}</h1>
      <div className="flex flex-col justify-center gap-5">
        <div>
          <h1 className='text-2xl font-extrabold pb-5'>{t('user_analytics')}</h1>
          <div className='grid grid-cols-12 gap-3'>
            {userAnalysis.data.map((item,index) => 
            <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md' key={index}>
                <h4 className='text-xl font-bold'>{t(`${item.type}`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{item.count}</span>
            </div>
            )}
            <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md'>
                <h4 className='text-xl font-bold'>{t(`total`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{total}</span>
            </div>
            <PieChart
            colors={['#c39f46','#1d1c22' , '#1d1c22']}
      series={[
        {
          data: transformedArray ?? [],
        },
      ]}
      width={400}
      height={200}
    />


          </div>
        </div>
        <div>
          <h1 className='text-2xl font-extrabold pb-5'>{t('consultation_analytics')}</h1>
            <div className='flex flex-col gap-4'>
            <div className='grid grid-cols-12 gap-3'>
            {consolationAnalysis.data.analysis?.map((item,index) => 
            <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md' key={index}>
                <h4 className='text-xl font-bold'>{t(`${item.status}`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{item.count}</span>
                {/* <h4 className='text-xl font-bold'>{t(`${item.status}`)}</h4> */}
            </div>
            )}
            <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md'>
                <h4 className='text-xl font-bold'>{t(`${'total'}`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{totalConsolationAnalysis}</span>
                {/* <h4 className='text-xl font-bold'>{t(`${item.status}`)}</h4> */}
            </div>

            <PieChart
              colors={['#c39f46','#1d1c22' , '#1d1c22']}
              series={[
                {
                  data: transformedConsolationsAnalysis ?? [],
                },
              ]}
              width={400}
              height={200}
            />
            </div>
            <div className='grid grid-cols-12 gap-3'>
            {consolationAnalysis.data.type?.map((item,index) => 
            <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md' key={index}>
                <h4 className='text-xl font-bold'>{t(`${item.type}`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{item.count}</span>
                {/* <h4 className='text-xl font-bold'>{t(`${item.status}`)}</h4> */}
            </div>
            )}
                        <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md'>
                <h4 className='text-xl font-bold'>{t(`${'total'}`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{totalConsolationType}</span>
                {/* <h4 className='text-xl font-bold'>{t(`${item.status}`)}</h4> */}
            </div>

                        <PieChart
              colors={['#c39f46','#1d1c22' , '#1d1c22']}
              series={[
                {
                  data: transformedConsolationsType ?? [],
                },
              ]}
              width={400}
              height={200}
            />
            </div>

            </div>
        </div>
        <div>
          <h1 className='text-2xl font-extrabold pb-5'>{t('ticket_analytics')}</h1>
          <div className='grid grid-cols-12 gap-3'>
            {ticketAnalysis.data.length>0 &&ticketAnalysis.data.map((item,index) => 
            <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md' key={index}>
                <h4 className='text-xl font-bold'>{t(`${item._id}`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{item.count ?? 0}</span>
            </div>
            )}
              <div className='col-span-12 md:col-span-6 lg:col-span-2 bg-card text-main-light p-4 flex flex-col gap-2 rounded-2xl shadow-black shadow-md'>
                <h4 className='text-xl font-bold'>{t(`${'total'}`)}</h4>
                <span className='text-6xl font-extrabold my-auto text-center'>{totalTickets}</span>
              </div>
              <PieChart
              colors={['#c39f46','#1d1c22' , '#1d1c22']}
              series={[
                {
                  data: transformedTickets ?? [],
                },
              ]}
              width={400}
              height={200}
            />
          </div>
        </div>
      </div>
      </>)}
    </div>
  )
}
