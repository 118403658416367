import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Consultant from './Consultant';
import { useTranslation } from 'react-i18next';
export default function ConsultantCard({item}) {
const {t} = useTranslation()
const [openEdit, setOpenEdit] = React.useState(false);
const closeEditModel = () => setOpenEdit(false);
const openEditModel = () => setOpenEdit(true);
  const date = new Date(item.reservationDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const arabicDate = new Intl.DateTimeFormat('ar-EG', options).format(date);
  let hours = date.getUTCHours();
const minutes = date.getUTCMinutes();
const ampm = hours >= 12 ? 'PM' : 'AM';
hours = hours % 12;
hours = hours ? hours : 12; // the hour '0' should be '12'
const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
const time = `${hours}:${formattedMinutes} ${ampm}`;
  console.log({item});
  return (
    <>
      <div className='bg-card shadow-md rounded-xl p-4 flex gap-3 flex-col relative'>
        <div className="flex items-center justify-between">
            <div className='flex gap-2'>
                <div className="w-20 h-20 border-main-light !border-4 rounded-lg overflow-hidden"><img className="w-full h-full" src={item.lawyer.profileImage} alt="" /></div>
                <div>
                  <h3 className='text-main-light text-lg font-bold py-2'>{item.lawyer.name}</h3>
                  <h4 className='text-white text-sm'>{t('consultant')}</h4>
                </div>
            </div>
            <div className='w-8 h-8 flex items-center cursor-pointer bg-main-light rounded-full' onClick={()=>openEditModel()}>
              <ArrowBackIosIcon/>
            </div>
        </div>
        <div className='flex gap-2'>
          <span className='flex gap-2 items-center text-white'><CalendarMonthIcon className='text-main'/>{arabicDate}</span>
          <span className='flex gap-2 items-center text-white'><ScheduleIcon className='text-main'/>{time}</span>
        </div>
      </div>
      {openEdit && 
        <Consultant item={item} handleClose={closeEditModel} open={openEdit} />
      }
    </>
  )
}
