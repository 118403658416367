import Modal from "@mui/material/Modal";
import { PlusOne, Remove } from "@mui/icons-material";
import React from 'react';
import { useTranslation } from "react-i18next";
import SwitchStatus from "../../Components/Shared/SwitchStatus";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as Yup from 'yup';
import client from "../../client";
import { API_ENDPOINTS } from "../../client/endpoints";
import { useCustomMutation } from "../../hooks/use-custome-mutation";
import { CircularProgress } from "@mui/material";
import  cookies  from 'js-cookie';

export default function AddPlan({handleClose,open}) {
  const locale = cookies.get('locale')
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [years, setYears] = React.useState('');
  const [months, setMonths] = React.useState('');
  const [days, setDays] = React.useState('');
  const { t } = useTranslation();
  const convertYearsMonthsDaysToSeconds = (years, months, days) => {
    return (years * 365 + months * 30 + days) * 24 * 60 * 60;
  };
    const createPlanMutation = useCustomMutation({
    mutationFn: client.Plans.add,
    queryToInvalidate: {queryKey: [API_ENDPOINTS.GET_ALL_PLANS]},
    onSuccess: (data) => {
      if (data.status) {
        setLoading(false);
        handleClose();
        createPlan.resetForm();
      }
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    },
  });

  const createPlan = useFormik({
    initialValues: {
      title: "",
      subTitle: "",
      durationSeconds: '',
      price: "",
      role:"verified_lawyer",
      // restrinctions: {
      //   consultants: ''
      // },
      features: [''],
      // status: true,
      isPremium: true,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      subTitle: Yup.string().required(),
      // durationSeconds: Yup.number().min(86400,'يجب ان يكون اقل وقت للباقة يوم كامل').required(),
      price: Yup.number().required(),
      // role: Yup.string().required(),
      // restrinctions: Yup.object({
      //   consultants: Yup.string().required('consultants is required'),
      // }),
      features: Yup.array().of(Yup.string().required('Feature cannot be empty')),
    }),
    onSubmit: (values) => {
      setLoading(true);
      // values.restrinctions.consultants = parseInt(values.restrinctions.consultants);
      values.features = values.features.filter(Boolean);
      values.durationSeconds = convertYearsMonthsDaysToSeconds(years, months, days);
      if(values.durationSeconds === 0) {
        setError('يجب ان يكون وقت الباقة علي الاقل يوم واحد')
        setLoading(false)
        setTimeout(()=>{
          setError('')
        },5000)
        return
      }
      createPlanMutation.mutate(values);
    }
  });
  return (
    <Modal  open={open} onClose={handleClose}>
      <div dir={locale==='en'?'rtl':'rtl'} className="bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-5 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
        <form onSubmit={createPlan.handleSubmit} className="w-64 md:w-[500px]">
          <div className="text-center mb-5">
            <h1 className="text-xl md:text-2xl font-bold text-main text-center">
              {t('add_new_plan')}
            </h1>
          </div>
          <div className="grid md:grid-cols-2 gap-x-3">
            <div>
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_name')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={createPlan.handleBlur}
                  onChange={createPlan.handleChange}
                  value={createPlan.values.title}
                  name="title"
                  type="text"
                  id="title"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_plan_name')}
                />
              </div>
            {createPlan.errors.title && createPlan.touched.title?<div className='text-red-500'>{createPlan.errors.title}</div>:''}
            </div>
            <div>
              <label
                htmlFor="subTitle"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_sub_name')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={createPlan.handleBlur}
                  onChange={createPlan.handleChange}
                  value={createPlan.values.subTitle}
                  name="subTitle"
                  type="text"
                  id="subTitle"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_plan_sub_name')}
                />
              </div>
              {createPlan.errors.subTitle && createPlan.touched.subTitle?<div className='text-red-500'>{createPlan.errors.subTitle}</div>:''}
            </div>
            <div>
              <label
                htmlFor="price"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_price')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={createPlan.handleBlur}
                  onChange={createPlan.handleChange}
                  value={createPlan.values.price}
                  name="price"              
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]{0,14}"
                  id="price"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_plan_price')}
                />
              </div>
              {createPlan.errors.price && createPlan.touched.price?<div className='text-red-500'>{createPlan.errors.price}</div>:''}
            </div>
            {/* <div>
              <label
                htmlFor="role"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_role')}
              </label>
              <div className="relative mb-2">
                <select
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  name="role"
                  onBlur={createPlan.handleBlur}
                  onChange={createPlan.handleChange}
                  value={createPlan.values.role}
                >
                  <option value={null}>Select Role</option>
                  <option value="lawyer">lawyer</option>
                  <option value="client">Client</option>
                </select>
              </div>
              {createPlan.errors.price && createPlan.touched.price?<div className='text-red-500'>{createPlan.errors.price}</div>:''}
            </div> */}
            <div className="col-span-2">
              <label htmlFor="durationSeconds" className="block mb-2 text-sm font-medium text-main">
                {t('input_plan_time')}
              </label>
              <div className=" grid grid-cols-3 gap-2">
              <div className="relative mb-2">
              <label
                htmlFor="years"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('year')}
              </label>
                <select
                  onChange={(e)=> setYears(e.target.value)}
                  name="years"
                  type="number"
                  min="0"
                  id="years"
                  className="appearance-none input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_plan_time')}
                >
                  {[...Array(11).keys()].map(year => (
                    <option key={year} value={year}>{year} {t('year')}</option>
                  ))}
                </select>
              </div>
              <div className="relative mb-2">
                <label
                  htmlFor="months"
                  className="block mb-2 text-sm font-medium text-main"
                >
                  {t('month')}
                </label>
                <select
                  onChange={(e)=> setMonths(e.target.value)}
                  name="months"
                  type="number"
                  min="0"
                  id="months"
                  className="appearance-none input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_plan_time')}
                >
                  {[...Array(13).keys()].map(month => (
                    <option key={month} value={month}>{month} {t('month')}</option>
                  ))}
                </select>
              </div>
              <div className="relative mb-2">
                <label
                  htmlFor="day"
                  className="block mb-2 text-sm font-medium text-main"
                >
                  {t('day')}
                </label>
                <select
                  onChange={(e)=> setDays(e.target.value)}
                  name="days"
                  type="number"
                  min="0"
                  id="day"
                  className="appearance-none input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_plan_time')}
                >
                  {[...Array(31).keys()].map(days => (
                    <option key={days} value={days}>{days} {t('day')}</option>
                  ))}
                </select>
              </div>
              </div>
              {createPlan.errors.duration && createPlan.touched.duration?<div className='text-red-500'>{createPlan.errors.duration}</div>:''}
            </div>
            {/* <div>
              <label
                htmlFor="restrinctions.consultants"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_consultants')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={createPlan.handleBlur}
                  onChange={createPlan.handleChange}
                  value={createPlan.values.restrinctions.consultants}
                  name="restrinctions.consultants"              
                  type="number"
                  min="0"
                  inputMode="numeric"
                  pattern="[0-9]{0,14}"
                  id="restrinctions.consultants"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_plan_consultants')}
                />
              </div>
              {createPlan.errors.restrinctions?.consultants && createPlan.touched.restrinctions?.consultants?<div className='text-red-500'>{createPlan.errors.restrinctions?.consultants}</div>:''}
            </div> */}
          </div>
          <div className="grid">
            <div>
              <label
                htmlFor="features"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_features')}
              </label>
            </div>
            <FormikProvider value={createPlan}>
              <FieldArray
                name="features"
                render={arrayHelpers => (
                  <ul>
                    {createPlan.values.features.map((feature, index) => (
                      <li className="grid grid-cols-5 gap-3" key={index}>
                        <div className="col-span-4 relative mb-2">
                          <input
                            type="text"
                            value={feature}
                            onChange={(e) => createPlan.setFieldValue(`features[${index}]`, e.target.value)}
                            id={`features-${index}`}
                            className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                            placeholder={t('placeholder_plan_features')}
                              />
                        </div>
                        {index !== 0 ? (
                          <div className="col-span-1 flex items-center">
                            <Remove  
                              className="text-white bg-main w-6 h-full p-1 rounded-full cursor-pointer"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </div>
                        ) : (
                          <div className="col-span-1 flex items-center">
                            <PlusOne  
                              className="text-white bg-main w-6 h-full p-1 rounded-full cursor-pointer"
                              onClick={() => arrayHelpers.push('')}
                            />
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              />
            </FormikProvider>
            {/* <div>
              <label
                htmlFor="status"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_status')}
              </label>
              <div className="relative mb-2">
                <SwitchStatus
                  checked={createPlan.values.status}
                  onChange={() => createPlan.setFieldValue('status', !createPlan.values.status)}
                />
              </div>
            </div> */}
            <div className={`${error?'mb-4':'mb-6'}`}>
              <label
                htmlFor="isPremium"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_plan_premium')}
              </label>
              <div className="relative mb-2">
                <SwitchStatus
                  checked={createPlan.values.isPremium}
                  onChange={() => createPlan.setFieldValue('isPremium', !createPlan.values.isPremium)}
                />
              </div>
            {error  && <div className='text-red-500 text-center mt-2'>{error}</div>}
            </div>
          </div>
          <button type='submit' disabled={!(createPlan.isValid && createPlan.dirty) || loading} className="btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold">
            {loading?<CircularProgress style={{
            width: '20px',
            height: '20px',
            color: '#fff',
          }} />:t('add')}
          </button>
        </form>
      </div>
    </Modal>
  );
}
