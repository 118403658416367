import Modal from "@mui/material/Modal";
import React from 'react';
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';

export default function Consultant({ handleClose, open, item }) {
  const locale = cookies.get('locale')
  const { t } = useTranslation();
  const [imageModalOpen, setImageModalOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState('');

  const date = new Date(item.reservationDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const arabicDate = new Intl.DateTimeFormat('ar-EG', options).format(date);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const time = `${hours}:${formattedMinutes} ${ampm}`;

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setSelectedImage('');
  };
  const isPDF = (url)=>{
    return url.split('.').pop().toLowerCase()==='pdf'?true :false
  }
    const handleDownloadPDF = (pathname) => {
      if (pathname) {
        const anchor = document.createElement('a');
        anchor.href = pathname;
        anchor.target = '_blank'; 
        anchor.download = 'file_name'; 
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }
    };
  console.log(item)
  return (
    <>
    <Modal open={open} onClose={handleClose}>
      <div dir={locale==='en'?'rtl':'rtl'} className="bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60%] p-5 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
        <div className='flex gap-3 flex-col'>
          <div className="flex items-center justify-between">
            <div className='flex gap-2'>
              <div className="w-20 h-20 border-main-light !border-4 rounded-lg overflow-hidden">
                <img className="w-full h-full" src={item.lawyer.profileImage} alt="" />
              </div>
              <div>
                <h3 className='text-main-light text-lg font-bold py-2'>{item.lawyer.name}</h3>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className='flex gap-2'>
              <div className="w-20 h-20 border-main-light !border-4 rounded-lg overflow-hidden">
                <img className="w-full h-full" src={item.client.profileImage} alt="" />
              </div>
              <div>
                <h3 className='text-main-light text-lg font-bold py-2'>{item.client.name}</h3>
              </div>
            </div>
          </div>
          <div className='flex gap-2'>
            <span className='flex gap-2 items-center text-white'>
              <CalendarMonthIcon className='text-main'/>{arabicDate}
            </span>
            <span className='flex gap-2 items-center text-white'>
              <ScheduleIcon className='text-main'/>{time}
            </span>
          </div>
        </div>
        <hr className='mt-5'/>
        {item.description &&
        <div className="text-center text-white py-3">
          <p className="text-xl">{item.description}</p>
        </div>
        }
        {item.description &&
        <div className="text-center text-white py-3">
          <p className="text-xl">{t('price')}: {item.price}</p>
        </div>
        }
        {item.attachments.length > 0 &&
        <div className="text-center text-white">
          <p className="text-xl pb-3 text-main-light">{t('files')}</p>
          {item.attachments.map((item, index)=> {
            return isPDF(item)?
             <div key={index} className="bg-black p-2 flex justify-between items-center">
              <div className="w-20 h-20 rounded-xl overflow-hidden">
                <img className="w-full h-full" src={item} alt="" />
              </div>
              <div className="text-main-light text-xl cursor-pointer" onClick={() => handleDownloadPDF(item)}>
                {t('download')}
              </div>
            </div>:
            <div key={index} className="bg-black p-2 flex justify-between items-center">
            <div className="w-20 h-20 rounded-xl overflow-hidden">
              <img className="w-full h-full" src={item} alt="" />
            </div>
            <div className="text-main-light text-xl cursor-pointer" onClick={() => handleImageClick(item)}>
              {t('show')}
            </div>
          </div>
          }
          )}
        </div>
        }
      </div>
    </Modal>
    <Modal open={imageModalOpen} onClose={handleImageModalClose}>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-5 bg-white rounded-lg shadow-lg">
        <img src={selectedImage} alt="" className="max-w-full max-h-full" />
      </div>
    </Modal>
    </>
  );
}
