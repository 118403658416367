import React from 'react'
import { useTermsConditions } from '../../data/vistor'
import { useTranslation } from 'react-i18next';
import AddTerms from '../../Components/Terms/AddTerms';
import TermsCard from '../../Components/Terms/TermsCard';
import Loading from '../../Components/Shared/Loading';

export default function TermsConditions() {
  const { t } = useTranslation();
  const { data , isPending } = useTermsConditions()
  const [openAdd, setOpenAdd] = React.useState(false);
  const closeAdd = () => setOpenAdd(false);
  const openAddModel = () => setOpenAdd(true);

  return (
    <div>
      <button
        className="btn-main-light px-5 py-3 rounded-lg font-bold text-2xl mb-4"
        onClick={openAddModel}
      >
        {t('add_terms')}
      </button>
      {isPending ? (
        <Loading />
      ) : data?.length ? (
        <>
        <h1 className='text-center text-3xl font-extrabold pb-5'>{t('terms_and_conditions')}</h1>
        {data?.length>0 && 
          <div className='bg-card rounded-md p-3'>
              {data.map((item, index)=>
                <TermsCard item={item} key={index}/>
              )
              }
          </div>
        }
        </>
        ):(<div className='flex items-center justify-center w-full h-96'>
          <div className='text-center text-3xl font-extrabold'>{t('not_available_terms_conditions')}</div>
        </div>)}
        <AddTerms handleClose={closeAdd} open={openAdd} />
    </div>
  )
}
