import { API_ENDPOINTS } from './endpoints';
import { HttpClient } from './http-client';

class Client {
  constructor() {
    this.auth = {
      getProfile: () =>
        HttpClient.get(API_ENDPOINTS.GET_PROFILE),
      login: (input) =>
        HttpClient.post(API_ENDPOINTS.LOGIN, input),
      logout: () => HttpClient.get(API_ENDPOINTS.LOGOUT),
    };

    this.Users = {
      get_all: (data) =>{
        return HttpClient.get(API_ENDPOINTS.GET_ALL_USERS,
          {
            params:data,
          }
        )
        },
      get: (id) =>
        HttpClient.get(API_ENDPOINTS.GET_USER(id)),
      get_analysis: () =>
        HttpClient.get(API_ENDPOINTS.GET_USER_ANALYSIS),
      verify_account: (id) =>
        HttpClient.patch(API_ENDPOINTS.VERIFY_ACCOUNT(id)),
      update_user_price:(data) =>
        HttpClient.put(API_ENDPOINTS.UPDATE_USER_PRICE, data),
      block_user:(data)=>
        HttpClient.patch(API_ENDPOINTS.BLOCK_USER(data.id), data),
    };

    this.Plans = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_PLANS),
      get: (id) =>
        HttpClient.get(API_ENDPOINTS.GET_PLAN(id)),
      add: (plan) =>
        HttpClient.post(API_ENDPOINTS.ADD_PLAN, plan),
      update: (plan) =>
        HttpClient.patch(API_ENDPOINTS.UPDATE_PLAN(plan._id), plan),
      delete: (id) =>
        HttpClient.delete(API_ENDPOINTS.DELETE_PLAN(id))
    };

    this.Advertisement = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_ADVERTISEMENTS),
      get: (id) =>
        HttpClient.get(API_ENDPOINTS.GET_ADVERTISEMENT(id)),
      add: (advertisement) =>
        HttpClient.post(API_ENDPOINTS.ADD_ADVERTISEMENT, advertisement),
      update: (advertisement) =>
        HttpClient.patch(API_ENDPOINTS.UPDATE_ADVERTISEMENT, advertisement),
      delete: (id) =>
        HttpClient.delete(API_ENDPOINTS.DELETE_ADVERTISEMENT(id))
    };

    this.Notifications = {
      send: (notification) =>
        HttpClient.post(API_ENDPOINTS.NOTIFICATION, notification)
    };
    this.TermsConditions = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_TERMS),
      add: (term) =>
        HttpClient.post(API_ENDPOINTS.ADD_TERMS, term),
      update: (term) =>
        HttpClient.patch(API_ENDPOINTS.UPDATE_TERMS(term._id), term),
      delete: (id) =>
        HttpClient.delete(API_ENDPOINTS.DELETE_TERMS(id))
    }
    this.AboutApp={
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_ABOUT_APP),
      add: (about) =>
        HttpClient.post(API_ENDPOINTS.ADD_ABOUT_APP, about),
      update: (about) =>
        HttpClient.patch(API_ENDPOINTS.UPDATE_ABOUT_APP(about._id), about),
      delete: (id) =>
        HttpClient.delete(API_ENDPOINTS.DELETE_ABOUT_APP(id))
    }
    this.Consultants = {
      get_all: () =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_CONSULTANTS),
      get: (id) =>
        HttpClient.get(API_ENDPOINTS.GET_CONSULTANT(id)),
      get_analysis: () =>
        HttpClient.get(API_ENDPOINTS.GET_CONSULTANTS_ANALYSIS),
    }
    this.Transactions = {
      get_all: (data) =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_TRANSACTIONS,{
          params: {
            from: data.from,
            to: data.to,
            type: data.type,
          },
        }),
      // update: (transaction) =>
      //   HttpClient.post(API_ENDPOINTS.UPDATE_TRANSACTION(transaction.id), transaction),
    }
    this.Transitions = {
      get_all: (userId) =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_TRANSITIONS,{
          params: {
            userId
          },
        }),
      update: (transition) =>
        HttpClient.patch(API_ENDPOINTS.UPDATE_TRANSITIONS(transition.id), transition),
    }
    this.Tickets = {
      get_all: (data) =>
        HttpClient.get(API_ENDPOINTS.GET_ALL_TICKETS,          {
          params: {
            limit:data.limit|| 100,
            page:data.page|| 1,
            status:data.status|| null,
          },
        }
),
      update: (ticket) =>
        HttpClient.patch(API_ENDPOINTS.UPDATE_TICKET(ticket._id), ticket),
      get_analysis: () =>
        HttpClient.get(API_ENDPOINTS.GET_TICKETS_ANALYSIS),
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Client();
