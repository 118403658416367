export const API_ENDPOINTS = {
  //Auth 
  LOGIN: '/api/v1/auth/signin',
  LOGOUT: '/api/v1/logout',
  GET_PROFILE: '/api/v1/getProfile',
  //User
  GET_ALL_USERS: '/api/v1/auth/users/crm',
  GET_USER: (id) =>`/api/v1/auth/users/${id}/crm`,
  VERIFY_ACCOUNT: (id)=>`/api/v1/auth/users/${id}/verify/crm`,
  BLOCK_USER: (id)=>`/api/v1/auth/users/${id}/crm`,
  GET_USER_ANALYSIS: '/api/v1/auth/analysis',
  UPDATE_USER_PRICE: 'api/v1/auth/users/price/crm',
  //Plan
  GET_ALL_PLANS: '/api/v1/plans',
  GET_PLAN:  (id) =>`api/v1/plans/${id}`,
  ADD_PLAN: '/api/v1/plans',
  UPDATE_PLAN:(id) => `/api/v1/plans/${id}`,
  DELETE_PLAN:(id) => `/api/v1/plans/${id}`,
  // Terms and Conditions
  GET_ALL_TERMS: '/api/v1/terms-conditions',
  ADD_TERMS: '/api/v1/terms-conditions',
  DELETE_TERMS:(id) => `/api/v1/terms-conditions/${id}`,
  UPDATE_TERMS: (id)=> `/api/v1/terms-conditions/${id}`,
  // About application
  GET_ALL_ABOUT_APP: '/api/v1/about-app',
  ADD_ABOUT_APP: '/api/v1/about-app',
  DELETE_ABOUT_APP:(id) => `/api/v1/about-app/${id}`,
  UPDATE_ABOUT_APP: (id)=> `/api/v1/about-app/${id}`,
  // Advertisement
  GET_ALL_ADVERTISEMENTS: '/api/v1/ads',
  GET_ADVERTISEMENT: (id)=> `/api/v1/ads/${id}`,
  ADD_ADVERTISEMENT: '/api/v1/ads',
  UPDATE_ADVERTISEMENT: '/api/v1/updateAdvertisement',
  DELETE_ADVERTISEMENT: (id)=> `/api/v1/ads/${id}`,
  // Consultants
  GET_CONSULTANTS_ANALYSIS:'/api/v1/consultants/analysis',
  GET_ALL_CONSULTANTS:'/api/v1/consultants/crm',
  GET_CONSULTANT:(id)=> `/api/v1/consultants/${id}`,
  //Notifications
  NOTIFICATION: '/api/v1/notifications/send',
  // Ticket
  GET_ALL_TICKETS: '/api/v1/tickets',
  ADD_TICKET: '/api/v1/tickets',
  UPDATE_TICKET: (id)=> `/api/v1/tickets/${id}`,
  GET_TICKETS_ANALYSIS: '/api/v1/tickets/analysis',
  //Transactions
  GET_ALL_TRANSACTIONS: '/api/v1/transactions/crm',
  //transitions
  GET_ALL_TRANSITIONS:'api/v1/transitions/crm',
  UPDATE_TRANSITIONS:(id)=> `/api/v1/transitions/${id}/crm`,

};