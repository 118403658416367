import React from "react";
import { useConsultants } from "../../data/vistor";
import Loading from "../../Components/Shared/Loading";
import { useTranslation } from "react-i18next";
import ConsultantCard from "../../Components/Consultants/ConsultantCard";

export default function Consultants() {
  const { t } = useTranslation();
  const { data, isPending } = useConsultants();

  return (
    <div>
      {isPending ? (
        <Loading />
      ) :data?.length? (
        <>
        <h1 className='text-center text-3xl font-extrabold pb-5'>{t('consultant')}</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {data?.map((item, index) => (
            <ConsultantCard item={item} key={index} />
          ))}
        </div>
        </>):(
         <div className='flex items-center justify-center w-full h-96'>
         <div className='text-center text-3xl font-extrabold'>{t('not_available_consultants')}</div>
       </div>
      )}
    </div>
  );
}
