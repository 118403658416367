import { Navigate } from 'react-router-dom';
import { useMe } from '../data/user';


const PrivateRoute = ({ children }) => {
  const { isAuthorized } = useMe();

  return isAuthorized ? children : <Navigate to={'/login'} />;
};

export default PrivateRoute;
