import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, Outlet, useLocation } from 'react-router-dom';
import PrivateRoute from '../private-route';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VrpanoOutlinedIcon from '@mui/icons-material/VrpanoOutlined';
import NotificationsPausedOutlinedIcon from '@mui/icons-material/NotificationsPausedOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useTranslation } from 'react-i18next';
import cookies  from 'js-cookie';
import { useAuth } from '../auth/context';
const drawerWidth = 240;
const locale = cookies.get('locale')

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundImage: 'linear-gradient(to top, rgba(251, 230, 123, 1) , rgba(212, 160, 65, 1))',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundImage: 'linear-gradient(to top, rgba(251, 230, 123, 1) , rgba(212, 160, 65, 1))',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent:'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'open',})(
  ({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundImage: 'linear-gradient(to right, rgba(251, 230, 123, 1) , rgba(212, 160, 65, 1))',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: locale==='en'?0:0,
    marginRight: locale==='ar'?drawerWidth:drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function AppLayout() {
  const { t } = useTranslation();
  const theme = useTheme();
  const {unauthorize} = useAuth()
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const AppLinks = [
    {
      icon: <TrendingUpIcon />,
      text: `${t('analytics')}`,
      to: '/',
    },
    {
      icon: <PeopleAltIcon />,
      text: `${t('lawyers')}`,
      to: '/lawyers',
    },
    {
      icon: <PeopleAltIcon />,
      text: `${t('users')}`,
      to: '/users',
    },
    {
      icon: <ListAltIcon />,
      text: `${t('plans')}`,
      to: '/plans',
    },
    {
      icon: <VrpanoOutlinedIcon />,
      text: `${t('advertisements')}`,
      to: '/advertisement',
    },
    {
      icon: <CompareArrowsIcon />,
      text: `${t('transactions')}`,
      to: '/transactions',
    },
    {
      icon: <GavelIcon />,
      text: `${t('consultants')}`,
      to: '/consultants',
    },
    {
      icon: <ConfirmationNumberIcon />,
      text: `${t('tickets')}`,
      to: '/tickets',
    },
    {
      icon: <NotificationsPausedOutlinedIcon />,
      text: `${t('notifications')}`,
      to: '/notifications',
    },
    {
      icon: <DocumentScannerIcon />,
      text: `${t('terms_and_conditions')}`,
      to: '/terms-conditions',
    },
    {
      icon: <InfoIcon />,
      text: `${t('about_app')}`,
      to: '/about-app',
    },
  ]
  // const [language, setlanguages] = React.useState(locale)
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  //   setlanguages(lng);
  //   cookies.set('locale',lng)
  //   window.location.reload();
  // };
  return (
    <PrivateRoute>
      <Box sx={{ display: 'flex' , overflowX:'auto' }}>
        <CssBaseline />
        <AppBar position="fixed" className='bg-main' open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge={locale==='en'?'start':'start'}
              sx={{
                marginRight: locale==='en'?0:0,
                marginLeft: locale==='en'?3:3,
                color:'#3b3d41',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <div style={{
              display:'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexGrow: 1,
            }}>
            <Typography variant="h4" noWrap component="div">
              {t('logo')}
            </Typography>
            <div style={{
              display:'flex',
              alignItems: 'end',
              gap:'10px'
            }}>
                {/* <div className='flex items-center gap-1'>
                  <span className='cursor-pointer' onClick={()=> changeLanguage(language ==='en'?'ar':'en')}>{language ==='en'?'AR':'EN'}</span>
                  <LanguageIcon/> 
                </div> */}
                <div className='text-white cursor-pointer' onClick={() =>  unauthorize() } >
                  <LogoutIcon />
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer anchor={locale==='en'?'right':'right'} variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton
            sx={{
              color:'#3b3d41',
            }}
            onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ?  <ChevronLeftIcon />:<ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <List>
            {AppLinks.map((item, index) => (
              <Link key={index} to={item.to}>
                <ListItem className={item.to === location.pathname? 'active-link':'text-second'}  disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                    title={item.text}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        color: item.to === location.pathname?'#c39f46':'#3b3d41',
                        ml: open ? (locale==='en'? 3:3 ): 'auto',
                        mr: open ? (locale==='en'? 0:0 ): 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText  primary={item.text} sx={{ textAlign:'start' , opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <DrawerHeader />
          <div className={open?'w-h-full-screen-open':'w-h-full-screen'}>
            <Outlet />
          </div>
        </Box>
      </Box>
    </PrivateRoute>
  );
}