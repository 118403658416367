import React from "react";
import { useTransactions, useUsers } from "../../data/vistor";
import Loading from "../../Components/Shared/Loading";
import { useTranslation } from "react-i18next";
import TransactionsCard from "../../Components/Transactions/TransactionsCard";
import ScrollLoading from "../../Components/Shared/ScrollLoading";

export default function Transactions() {
  const { t } = useTranslation();
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);
  const [selectedPagination , setSelectedPagination] = React.useState({
    from :null , 
    to :null , 
    type :null
  })
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const { data, isPending , isError } = useTransactions({
    from : selectedPagination.from, 
    to :selectedPagination.to , 
    type :selectedPagination.type  
  });

  const [userData, setUserData] = React.useState([]);
  const { data: fetchedUsers = {}, isPending: isLoadingUsers } = useUsers({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
  });

  const fromSelectRef = React.useRef(null);
  const toSelectRef = React.useRef(null);

  React.useEffect(() => {
    if (fetchedUsers.data) {
      setIsInitialLoading(false);
      setUserData((prevUserData) => [...prevUserData, ...fetchedUsers.data]);
    }
    if(isError){
      setIsInitialLoading(false);
    }
  }, [fetchedUsers.data,isError]);

  React.useEffect(() => {
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom && !isLoadingUsers && pagination.pageSize < fetchedUsers.pagination?.resultCount) {
        setPagination((prev) => ({
          ...prev,
          pageSize: prev.pageSize + 50,
        }));
      }
    };

    const fromSelect = fromSelectRef.current;
    const toSelect = toSelectRef.current;

    if (fromSelect && toSelect) {
      fromSelect.addEventListener('scroll', handleScroll);
      toSelect.addEventListener('scroll', handleScroll);

      return () => {
        fromSelect.removeEventListener('scroll', handleScroll);
        toSelect.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isLoadingUsers, fetchedUsers, pagination.pageSize]);

  return (
    <div>
      <div className="grid grid-cols-3 gap-5 pb-5">
        <select
          onChange={(e)=> setSelectedPagination({...selectedPagination , type:null , from:e.target.value ===''? null : e.target.value})}
          ref={fromSelectRef}
          className="input appearance-none bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5 overflow-auto"
          name="from"
        >
          <option value=''>{t('from')}</option>
          {userData?.map((user,index) => (
            <option key={user._id+index} value={user._id}>{user.name}</option>
          ))}
        </select>
        <select
          onChange={(e)=> setSelectedPagination({...selectedPagination , type:null , to:e.target.value ===''? null : e.target.value})}
          ref={toSelectRef}
          className="input appearance-none bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5 overflow-auto"
          name="to"
        >
          <option value=''>{t('to')}</option>
          {userData?.map(user => (
            <option key={user._id} value={user._id}>{user.name}</option>
          ))}
        </select>
        <select
          onChange={(e)=> setSelectedPagination({from:null , to:null , type:e.target.value ===''? null : e.target.value})}
          className="input appearance-none bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full p-2.5 overflow-auto"
          name="type"
        >
          <option value=''>{t('type')}</option>
          <option value="system-to-user">{t('system-to-user')}</option>
          <option value="user-to-system">{t('user-to-system')}</option>
          <option value="user-to-user">{t('user-to-user')}</option>
        </select>
      </div>
      {isInitialLoading && <Loading />}
      {isPending ? (
        <ScrollLoading />
      ) : data?.data?.length ? (
        <div className="flex flex-col gap-5">
          <h1 className="text-center text-3xl font-extrabold pb-5">{t('transactions')}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {data?.data?.map((item, index) => (
              <TransactionsCard item={item} key={index} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-96">
          <div className="text-center text-3xl font-extrabold">{t('not_available_transactions')}</div>
        </div>
      )}
    </div>
  );
}
