import React from 'react';
import { useTickets } from '../../data/vistor';
import { useTranslation } from 'react-i18next';
import TicketsCard from '../../Components/Tickets/TicketsCard';
import Loading from '../../Components/Shared/Loading';
import ScrollLoading from '../../Components/Shared/ScrollLoading';

export default function Tickets() {
  const { t } = useTranslation();
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [tickets, setTickets] = React.useState([]);

  const { data = {}, isPending , isError } = useTickets({
    page: pagination.pageIndex + 1,
    limit: pagination.pageSize,
  });
  const [isInitialLoading, setIsInitialLoading] = React.useState(true);

  React.useEffect(() => {
    if (data.data) {
      setIsInitialLoading(false);
      setTickets((prevtickets) => [...prevtickets, ...data.data]);
    }
    if(isError){
      setIsInitialLoading(false)
    }
  }, [data.data, isError]);

  React.useEffect(() => {
    const handleScroll = () => {
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      if (bottom && !isPending && pagination.pageSize < data.pagination?.resultCount) {
        setPagination((prev) => ({
          ...prev,
          pageSize: prev.pageSize + 20,
        }));
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isPending, data, pagination.pageSize]);

  return (
    <div>
      {isInitialLoading && <Loading />}
      {isPending ? (
        <ScrollLoading />
      ) : tickets?.length ? (
        <div className="flex flex-col gap-5">
          <h1 className="text-center text-3xl font-extrabold pb-5">{t('tickets')}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {tickets?.map((item, index) => (
          <TicketsCard item={item} key={index} />
        ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-96">
          <div className="text-center text-3xl font-extrabold">{t('not_available_tickets')}</div>
        </div>
      )}
    </div>
  );
}
