import React from 'react'
import { useAboutApp } from '../../data/vistor'
import { useTranslation } from 'react-i18next';
import AddAbout from '../../Components/About/AddAbout';
import AboutCard from '../../Components/About/AboutCard';
import Loading from '../../Components/Shared/Loading';

export default function About() {
  const { t } = useTranslation();
  const { data , isPending } = useAboutApp()
  const [openAdd, setOpenAdd] = React.useState(false);
  const closeAdd = () => setOpenAdd(false);
  const openAddModel = () => setOpenAdd(true);

  return (
    <div>
      <button
        className="btn-main-light px-5 py-3 rounded-lg font-bold text-2xl mb-4"
        onClick={openAddModel}
      >
        {t('add_about')}
      </button>
      {isPending ? (
        <Loading />
      ) : data?.length?(
        <>
        <h1 className='text-center text-3xl font-extrabold pb-5'>{t('about_app')}</h1>
        {data?.length>0 && 
          <div className='bg-card rounded-md p-3'>
              {data.map((item,index)=>
              <AboutCard item={item} key={index} />)
              }
          </div>
        }
        </>
      ):(<div className='flex items-center justify-center w-full h-96'>
        <div className='text-center text-3xl font-extrabold'>{t('not_available_about_app')}</div>
      </div>)}
        <AddAbout handleClose={closeAdd} open={openAdd} />
    </div>
  )
}
