import Modal from "@mui/material/Modal";
import React from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import client from "../../client";
import { API_ENDPOINTS } from "../../client/endpoints";
import { useCustomMutation } from "../../hooks/use-custome-mutation";
import { CircularProgress } from "@mui/material";
import  cookies  from 'js-cookie';

export default function AddNotification({handleClose,open , selectedUserIds}) {
  const locale = cookies.get('locale')
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { t } = useTranslation();

  const sendNotificationMutation = useCustomMutation({
    mutationFn: client.Notifications.send,
    queryToInvalidate: {queryKey: [API_ENDPOINTS.GET_ALL_notification_APP]},
    onSuccess: (data) => {
      if (data.status) {
        setLoading(false);
        handleClose();
        sendNotification.resetForm();
      }
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    },
  });
  const sendNotification = useFormik({
    initialValues: {
      title: "",
      body: "",
      to:[...selectedUserIds]
    },
    validationSchema: Yup.object({
      title: Yup.string().required(),
      body: Yup.string().required(),
    }),
    onSubmit: (values) => {
      setLoading(true);
      values.to = [...selectedUserIds];
      sendNotificationMutation.mutate(values);
    }
  });

  return (
    <Modal  open={open} onClose={handleClose}>
      <div dir={locale==='en'?'rtl':'rtl'} className="bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-5 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
        <form onSubmit={sendNotification.handleSubmit} className="w-64 md:w-[500px]">
          <div className="text-center mb-5">
            <h1 className="text-xl md:text-2xl font-bold text-main text-center">
              {t('add_new_notification')}
            </h1>
          </div>
          <div className="grid md:grid-row-2 gap-x-3">
            <div>
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_notification_title')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={sendNotification.handleBlur}
                  onChange={sendNotification.handleChange}
                  value={sendNotification.values.title}
                  name="title"
                  type="text"
                  id="title"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_notification_title')}
                />
              </div>
            {sendNotification.errors.title && sendNotification.touched.title?<div className='text-red-500'>{sendNotification.errors.title}</div>:''}
            </div>
            <div>
              <label
                htmlFor="body"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_notification_body')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={sendNotification.handleBlur}
                  onChange={sendNotification.handleChange}
                  value={sendNotification.values.body}
                  name="body"
                  type="text"
                  id="body"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_notification_body')}
                />
              </div>
              {sendNotification.errors.body && sendNotification.touched.body?<div className='text-red-500'>{sendNotification.errors.body}</div>:''}
            </div>
          </div>
          <button type='submit' disabled={!(sendNotification.isValid && sendNotification.dirty) || loading} className="btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold">
            {loading?<CircularProgress style={{
            width: '20px',
            height: '20px',
            color: '#fff',
          }} />:t('send')}
          </button>
        </form>
      </div>
    </Modal>
  );
}
