import Modal from "@mui/material/Modal";
import React from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import client from "../../client";
import { API_ENDPOINTS } from "../../client/endpoints";
import { useCustomMutation } from "../../hooks/use-custome-mutation";
import { CircularProgress } from "@mui/material";
import  cookies  from 'js-cookie';

export default function AddAdvertisement({handleClose,open}) {
  const locale = cookies.get('locale')
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const { t } = useTranslation();

  const createAdvertisementMutation = useCustomMutation({
    mutationFn: client.Advertisement.add,
    queryToInvalidate: {queryKey: [API_ENDPOINTS.GET_ALL_ADVERTISEMENTS]},
    onSuccess: (data) => {
      if (data.status) {
        setLoading(false);
        handleClose();
        createAdvertisement.resetForm();
      }
    },
    onError: (error) => {
      setLoading(false);
      setError(error.message);
      setTimeout(() => {
        setError('');
      }, 5000);
    },
  });

const createAdvertisement = useFormik({
  initialValues: {
    title: "",
    image: null,
  },
  validationSchema: Yup.object({
    title: Yup.string().required(t('validation_title_required')),
    image: Yup.mixed().required(t('validation_image_required')),
  }),
  onSubmit: (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('image', values.image);

    createAdvertisementMutation.mutate(formData);
  }
});

  return (
    <Modal open={open} onClose={handleClose}>
      <div dir={locale==='en'?'rtl':'rtl'} className="bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-5 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
        <form onSubmit={createAdvertisement.handleSubmit} className="w-64 md:w-[500px]">
          <div className="text-center mb-5">
            <h1 className="text-xl md:text-2xl font-bold text-main text-center">
              {t('add_new_advertisement')}
            </h1>
          </div>
          <div className="grid md:grid-row-2 gap-x-3">
            <div>
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_advertisement_title')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={createAdvertisement.handleBlur}
                  onChange={createAdvertisement.handleChange}
                  value={createAdvertisement.values.title}
                  name="title"
                  type="text"
                  id="title"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg  block w-full p-2.5"
                  placeholder={t('placeholder_advertisement_title')}
                />
              </div>
            {createAdvertisement.errors.title && createAdvertisement.touched.title?<div className='text-red-500'>{createAdvertisement.errors.title}</div>:''}
            </div>
            <div>
              <label
                htmlFor="image"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_advertisement_image')}
              </label>
              <div className="relative mb-2">
              <input
                onBlur={createAdvertisement.handleBlur}
                onChange={(event) => createAdvertisement.setFieldValue('image', event.currentTarget.files[0])}
                name="image"
                type="file"
                id="image"
                className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                placeholder={t('placeholder_advertisement_image')}
              />
              </div>
              {createAdvertisement.errors.image && createAdvertisement.touched.image?<div className='text-red-500'>{createAdvertisement.errors.image}</div>:''}
            </div>
          </div>
          <button type='submit' disabled={!(createAdvertisement.isValid && createAdvertisement.dirty) || loading} className="btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold">
            {loading?<CircularProgress style={{
            width: '20px',
            height: '20px',
            color: '#fff',
          }} />:t('add')}
          </button>
        </form>
      </div>
    </Modal>
  );
}
