import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Edit from '@mui/icons-material/Edit';
import LongMenu from '../Shared/Menu';
import { Delete } from '@mui/icons-material';
import client from '../../client';
import { API_ENDPOINTS } from '../../client/endpoints';
import SwitchStatus from '../Shared/SwitchStatus';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import EditPlan from './EditPlan';
export default function PlanCard({item}) {
  const [status , setStatus] = React.useState(false)
  React.useEffect(() => {
    if (item) {
      setStatus(item.status)
    }
}, [item]);
const [openEdit, setOpenEdit] = React.useState(false);
const closeEditModel = () => setOpenEdit(false);
const openEditModel = () => setOpenEdit(true);

  const deleteMutation = useCustomMutation({
    mutationFn: client.Plans.delete,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_ALL_PLANS],
    },
  });
  const updateMutation = useCustomMutation({
    mutationFn: client.Plans.update,
    queryToInvalidate: {queryKey: [API_ENDPOINTS.GET_ALL_PLANS],},
  });

  const toggleStatus = () => {
    setStatus(!status)
    updateMutation.mutate({_id:item._id,status:!status , role : item.role})
  } 

  return (
    <>
      <div className='bg-card shadow-md rounded-xl gap-4 pt-6 pb-[100px] flex flex-col relative'>
        <div>
          <div className='flex items-center w-full justify-between px-5'>
            <div className='btn-main px-2 py-1 rounded-xl'>
              {item.price}$
            </div>
            <h2 className='btn-card text-main-light text-center'>{item.title}</h2>
            <LongMenu options={[{
              component: <Edit className='text-red-600'/> ,
              action: ()=> {
                openEditModel()
              }
            },{
              component: <Delete className='text-red-600'/> ,
              action: ()=> deleteMutation.mutate(item._id)
            }]}/>
          </div>
        </div>
       <h4 className='text-xl font-bold text-main px-4 capitalize'>{item?.subTitle}</h4>
       <div className='bg-gray-800 h-[1px] '/>
        <div className='flex flex-col gap-5 px-4'>
          {item.features.map((feature,index)=>
          <div key={index} className='flex items-center gap-3'>
              <CheckCircleIcon className='text-main-light'/>
              <h4 className='text-xl font-bold text-white'>{feature}</h4>
          </div>
          )}
        </div>
        <div className='flex absolute bottom-4 px-4'>
            <SwitchStatus
                checked={status}
                onChange={toggleStatus} 
            />
        </div>
      </div>
      {openEdit && 
        <EditPlan item={item} handleClose={closeEditModel} open={openEdit} />
      }
    </>
  )
}
