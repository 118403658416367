import React from "react";
import PlanCard from "../../Components/Plan/PlanCard";
import { usePlans } from "../../data/vistor";
import Loading from "../../Components/Shared/Loading";
import { useTranslation } from "react-i18next";
import AddPlan from "../../Components/Plan/AddPlan";

export default function Plans() {
  const { t } = useTranslation();
  const [openAdd, setOpenAdd] = React.useState(false);
  const { data, isPending } = usePlans();
  const closeAdd = () => setOpenAdd(false);
  const openAddModel = () => setOpenAdd(true);
  return (
    <div>
      <button
        className="btn-main-light px-5 py-3 rounded-lg font-bold text-2xl mb-4"
        onClick={openAddModel}
      >
        {t('add_plan')}
      </button>
      {isPending ? (
        <Loading />
      ) :data?.length? (
        <>
        <h1 className='text-center text-3xl font-extrabold pb-5'>{t('plans')}</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {data?.map((item, index) => (
            <PlanCard item={item} key={index} />
          ))}
        </div>
        </>
      ):(
        <div className='flex items-center justify-center w-full h-96'>
          <div className='text-center text-3xl font-extrabold'>{t('not_available_plans')}</div>
        </div>
      )}
      <AddPlan handleClose={closeAdd} open={openAdd} />
    </div>
  );
}
