import React from 'react'
import { useTranslation } from 'react-i18next'
export default function TransactionsCard({item}) {
  const {t} = useTranslation()
  const date = new Date(item.createdAt);
const formattedDate = date.toISOString().split('T')[0];

  return (
    <>
      <div className='bg-card shadow-md rounded-xl text-white gap-4 py-6 flex flex-col relative  px-5'>
          <div className='flex items-center justify-between'>
            <div className="bg-main px-1 py-2 rounded-lg border-main-light text-main-light font-bold text-xl">{formattedDate}</div>
          </div>
              <p className='font-bold text-xl text-main'>{t('transition_amount')} <span className='text-main-light'>{Math.abs(item.amount)} {t('dinar')}</span></p>
            <div className='grid grid-cols-2 w-full justify-between items-center'>
            {(item.type==='user-to-user')||(item.type==='user-to-system') ?
            <>
              <div className='flex items-center gap-2 max-h-5'>
                {t('from')}
                <div className='w-10 h-10'>
                  <img
                    alt="Profile_Image"
                    src={item.from?.profileImage}
                    loading="lazy"
                    className='w-full h-full rounded-full'
                    />
                </div>
                <span>{item.from?.name}</span>
              </div>
            </>: (item.type ==='system-to-user'&&
              <div className='flex items-center gap-2 max-h-5'>
                {t('from_system')}
                </div>
            )
            }
            {(item.type==='user-to-user')||(item.type==='system-to-user') ?
            <>
              <div className='flex items-center gap-2 max-h-5'>
              {t('to')}
              <div className='w-10 h-10'>
                  <img
                    alt="Profile_Image"
                    src={item.to?.profileImage}
                    loading="lazy"
                    className='w-full h-full rounded-full'
                    />
                </div>
                <span>{item.to?.name}</span>
              </div>
            </>: (item.type ==='user-to-system'&&
              <div className='flex items-center gap-2 max-h-5'>
                {t('to_system')}
                </div>
            )
            }

              {/* <p className='font-bold text-xl text-main'>{item.amount>0?t('transaction_amount_add'):t('transaction_amount_remove')} <span className='text-main-light px-2'>{Math.abs(item.amount)} {t('dinar')}</span> {item.amount>0?t('to_your_account'):t('from_your_account')}</p> */}
          </div>
      </div>
    </>
  )
}
