import React from 'react'
import Edit from '@mui/icons-material/Edit';
import LongMenu from '../Shared/Menu';
import { Delete } from '@mui/icons-material';
import client from '../../client';
import { API_ENDPOINTS } from '../../client/endpoints';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import EditAbout from './EditAbout';
export default function AboutCard({item}) {
const [openEdit, setOpenEdit] = React.useState(false);
const closeEditModel = () => setOpenEdit(false);
const openEditModel = () => setOpenEdit(true);

  const deleteMutation = useCustomMutation({
    mutationFn: client.AboutApp.delete,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_ALL_ABOUT_APP],
    },
  });

  return (
    <>
      <div className='bg-card shadow-md rounded-xl gap-4 pt-6 flex flex-col relative'>
        <div>
          <div className='flex w-full justify-between px-5'>
            <div>
                <h1 className='text-main-light text-xl font-bold py-2'>{item.title}</h1>
                <p className='text-white'>{item.description}</p>
            </div>
            <LongMenu options={[{
              component: <Edit className='text-red-600'/> ,
              action: ()=> {
                openEditModel()
              }
            },{
              component: <Delete className='text-red-600'/> ,
              action: ()=> deleteMutation.mutate(item._id)
            }]}/>
          </div>
        </div>
      </div>
      {openEdit && 
        <EditAbout item={item} handleClose={closeEditModel} open={openEdit} />
      }
    </>
  )
}
