import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Home from './Pages/Home/Home';
import Plans from './Pages/Plans/Plans';
import Advertisements from './Pages/Advertisements/Advertisements';
import NotFound from './Pages/NotFound/NotFound';
import AppLayout from './Components/Layout/AppLayout';
import Notifications from './Pages/Notifications/Notifications';
import TermsConditions from './Pages/TermsConditions/TermsConditions';
import About from './Pages/About/About';
import Users from './Pages/Users/Users';
import User from './Pages/User/User';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { faIR } from '@mui/material/locale';
import './index.css'
import Consultants from './Pages/Consultants/Consultants';
import Tickets from './Pages/Tickets/Tickets';
import Transactions from './Pages/Transactions/Transactions';
import Lawyers from './Pages/Laywers/Lawyers';


function App() {
  const theme = useTheme(); //replace with your theme/createTheme

  return (
    <div style={{ direction:'rtl'}}>
    <ThemeProvider theme={createTheme({ ...theme, direction:'rtl' }, faIR)}>
    <Router> 
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/users" element={<Users />} />
          <Route path="/lawyers" element={<Lawyers />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/advertisement" element={<Advertisements />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/about-app" element={<About />} />
          <Route path="/consultants" element={<Consultants />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/transactions" element={<Transactions />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </ThemeProvider>
    </div>
  );
}

export default App;
