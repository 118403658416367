import Modal from "@mui/material/Modal";
import React from 'react';
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import client from "../../client";
import { API_ENDPOINTS } from "../../client/endpoints";
import { useCustomMutation } from "../../hooks/use-custome-mutation";
import { CircularProgress } from "@mui/material";
import cookies from 'js-cookie';
import { useParams } from "react-router-dom";

export default function Block({ handleClose, open, userData }) {
  const { id } = useParams();
  const locale = cookies.get('locale');
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  
  const blockMutation = useCustomMutation({
    mutationFn: client.Users.block_user,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_USER(id)],
    },
    onSuccess:()=>{
      handleClose();
      setLoading(false);
    }
  });

  const HandleBlock = () => {
    if(!userData.data.isBlocked.value){
      blockMutation.mutate({
        id,
        block:true,
        reason:blockUserFormik.values.reason
      })
    }else{
      blockMutation.mutate({
        id,
        block:false
      })
    }
  }

  const blockUserFormik = useFormik({
    initialValues: {
      block:true,
      reason: '',
    },
    onSubmit: () => {
      setLoading(true);
      HandleBlock()
    }
  });
  return (
    <Modal open={open} onClose={handleClose}>
      <div dir={locale === 'en' ? 'rtl' : 'rtl'} className="bg-card absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 px-16 md:px-32 py-5 rounded-3xl shadow-2xl max-h-[600px] overflow-auto">
        <form onSubmit={blockUserFormik.handleSubmit} className="w-64 md:w-[500px]">
          <div className="text-center mb-5">
            <h1 className="text-xl md:text-2xl font-bold text-main text-center">
              {t('make_block_user')}
            </h1>
          </div>
          <div className="grid gap-x-3">
            {!userData.data.isBlocked.value &&
            <div>
              <label
                htmlFor="reason"
                className="block mb-2 text-sm font-medium text-main"
              >
                {t('input_block_reason')}
              </label>
              <div className="relative mb-2">
                <input
                  onBlur={blockUserFormik.handleBlur}
                  onChange={blockUserFormik.handleChange}
                  value={blockUserFormik.values.reason}
                  name="reason"
                  type="text"
                  id="reason"
                  className="input bg-gray-50 border border-main text-gray-500 text-sm rounded-lg block w-full p-2.5"
                  placeholder={t('placeholder_block_reason')}
                />
              </div>
            </div>
            }
          </div>
          <button type='submit' disabled={loading} className="btn-main text-main flex items-center justify-center w-full py-3 rounded-lg text-xl font-bold">
            {loading ? <CircularProgress style={{
              width: '20px',
              height: '20px',
              color: '#fff',
            }} /> : t('block')}
          </button>
        </form>
      </div>
    </Modal>
  );
}
