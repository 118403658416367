import { useQuery } from '@tanstack/react-query';
import client from '../client';
import { API_ENDPOINTS } from '../client/endpoints';
export const useUsers = (data) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_USERS, data],
    queryFn: () => client.Users.get_all(data),
    keepPreviousData: true,
  });
};
export const useUser = (id) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_USER(id)],
    queryFn: ()=> client.Users.get(id),
  });    
  return {
    isPending,
    isError,
    error,
    data: data?.data ?? [],
  };
};
export const usePlans = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_PLANS],
    queryFn: client.Plans.get_all,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useUserAnalysis = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_USER_ANALYSIS],
    queryFn: client.Users.get_analysis,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useConsultantsAnalysis = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_CONSULTANTS_ANALYSIS],
    queryFn: client.Consultants.get_analysis,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useTicketsAnalysis = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_TICKETS_ANALYSIS],
    queryFn: client.Tickets.get_analysis,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useConsultants = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_CONSULTANTS],
    queryFn: client.Consultants.get_all,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useAdvertisement = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_ADVERTISEMENTS],
    queryFn: client.Advertisement.get_all,
  });    

  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useTermsConditions = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_TERMS],
    queryFn: client.TermsConditions.get_all,
  });
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
};
export const useAboutApp = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_ABOUT_APP],
    queryFn: client.AboutApp.get_all,
  });
  return {
    isPending,
    isError,
    error,
    data: data?.data??[],
  };
}

export const useTransactions = (pagination) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_TRANSACTIONS,pagination],
    queryFn: () => client.Transactions.get_all(pagination),
    keepPreviousData: true,
  });
}
export const useTransitions = ({userId}) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_TRANSITIONS,userId],
    queryFn: () => client.Transitions.get_all(userId),
    keepPreviousData: true,
  });
}

export const useTickets = ({ page, limit, search }) => {
  return useQuery({
    queryKey: [API_ENDPOINTS.GET_ALL_TICKETS, { page, limit, search }],
    queryFn: () => client.Tickets.get_all({ page, limit, search }),
    keepPreviousData: true,
  });
};

