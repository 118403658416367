import React from 'react'
import EditTickets from './EditTickets';
import { useTranslation } from 'react-i18next';
export default function TicketsCard({item}) {
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = React.useState(false);
  const closeEditModel = () => setOpenEdit(false);
  const openEditModel = () => setOpenEdit(true);


  return (
    <>
      <div className='bg-card shadow-md rounded-xl gap-4 py-6 flex flex-col relative'>
        <div>
          <div className='flex w-full justify-between px-5'>
            <div>
                <h1 className='text-main-light font-bold py-2'>{item.email}</h1>
                <p className='text-white'>{t('ticket')} : {item.message}</p>
                {item.feedback && <p className='text-white'>{t('feedback')} : {item.feedback}</p>}
            </div>
            <div>
              <div onClick={()=> openEditModel()} className='py-2 px-3 bg-main text-white rounded-2xl cursor-pointer'>
              {t('answer')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openEdit && 
        <EditTickets item={item} handleClose={closeEditModel} open={openEdit} />
      }
    </>
  )
}
